@tailwind base;
@tailwind components;
@tailwind utilities;




@layer utilities {
    @media print {
      body {
        display: none !important;
      }

      *{
        display: none !important;
      }

      .print-disable {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        position: absolute !important;
        pointer-events: none !important;
      }
    }
  }