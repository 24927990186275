@tailwind base;
@tailwind components;
@tailwind utilities;




/* .sv_main {
    background-color: transparent;
    background-image: url(https://image.shutterstock.com/image-photo/agave-cactus-abstract-natural-pattern-600w-1056037874.jpg);
    background-color: rgba(0,0,0,0.9);
  }
  
  .sv_main .sv_body, .sv_header {
    background-color: white;
    opacity: 0.85;
  }
  
  .sv_main .sv_body .sv_p_root > .sv_row {
    background-color: transparent !important;
  } */


  @layer utilities {
    @media print {
      body {
        display: none !important;
      }

      *{
        display: none !important;
      }

      .print-disable {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        position: absolute !important;
        pointer-events: none !important;
      }
    }
  }